<div class="content">

    <!-- Content -->
    <section class="content-page">
        <ng-container *ngIf="dataStatus === statusEnum.DONE">
            <div class="row">
                <div class="col-12 col-lg-5">
                    <div class="card ticket-detail">
                        <div class="card-header dashed-border">
                            <div class="light-grey">Consulta N° <div class="number">{{ ticketData.nroTicket }}
                                </div>
                            </div>
                            <agd-reclamo-estado-consulta
                                [ticketStatus]="ticketData.estado"></agd-reclamo-estado-consulta>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <div class="module">{{ ticketData.motivo }}</div>
                                    <div class="sub-module">{{ ticketData.subMotivo }}</div>
                                </div>
                            </div>
                            <ng-container *ngIf="hasPermission">
                                <div class="row">
                                    <div class="col">
                                        <div>Razón Social</div>
                                        <div>{{ ticketData.razonSocial }}</div>
                                    </div>
                                    <div class="col">
                                        <div>CUIT</div>
                                        <div>{{ ticketData.cuit }}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div>Usuario</div>
                                        <div>
                                            {{ ticketData.usuario.username }}
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div>Rol</div>
                                        <div>{{ ticketData.rol }}</div>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="row">
                                <div class="col">
                                    <div>Fecha de creación</div>
                                    <div>{{ ticketData.fechaInicio | date: 'dd/MM/yyyy HH:mm' }}</div>
                                </div>
                                <div class="col">
                                    <div>Fecha de finalización</div>
                                    <div>{{ ticketData.fechaFinalizacion ? (ticketData.fechaFinalizacion | date:
                                        'dd/MM/yyyy HH:mm') : '-' }}</div>
                                </div>
                            </div>
                            <div class="row" *ngIf="ticketData.tipoDocumento">
                                <div class="col">
                                    <div>Tipo de documento</div>
                                    <div>{{ ticketData.tipoDocumento }}</div>
                                </div>
                                <div class="col">
                                    <div>Número de documento</div>
                                    <div>{{ ticketData.nroDocumento }}</div>
                                </div>
                            </div>
                            <div class="row" *ngIf="hasPermission">
                                <div class="col">
                                    <div>Acopio/s</div>
                                    <div class="acopios">
                                        <span class="badge" *ngFor="let acopio of ticketData.acopios">
                                            {{ acopio }}
                                        </span>
                                        <div class="ml-1" *ngIf="!ticketData?.acopios?.length">Sin acopios</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-7 mt-3 mt-lg-0">
                    <div class="card ticket-comments">
                        <div class="timeline-header">
                            <div>Detalle de la consulta</div>
                            <button class="btn btn-primary re-open" *ngIf="reOpen" (click)="reOpenTicket()" fadeIn>
                                <div class="d-flex align-items-center justify-content-center" @fadeIn>
                                    <i class="lock material-icons-outlined mr-2">lock</i>
                                    <i class="lock-open material-icons-outlined mr-2 ">lock_open</i>
                                    Reabrir consulta
                                </div>
                            </button>
                            <button class="btn btn-dark" *ngIf="canClose" @fadeIn (click)="closeTicket()"
                                [disabled]="commentLoading">
                                <div class="d-flex align-items-center justify-content-center"
                                    [ngClass]="{ invisible: closeLoading }" @fadeIn>
                                    <i class="material-icons-outlined mr-2">lock</i>
                                    Finalizar consulta
                                </div>
                                <i class="spinner-icon" *ngIf="closeLoading">
                                    <mat-progress-spinner mode="indeterminate" diameter="19">
                                    </mat-progress-spinner>
                                </i>
                            </button>
                            <mat-icon class="material-icons-outlined lock-icon" *ngIf="!canClose && !hasPermission"
                                @fadeIn>lock</mat-icon>
                        </div>
                        <div class="poll-container" *ngIf="!canClose && !hasPermission">
                            <div class="poll-box">
                                <img class="lock-icon" src="assets/images/logo-ticket-closed.png" />
                                <span class="poll-text">Espero que hayamos podido ayudarte.</span>
                                <span class="flex-grow-1 d-flex justify-content-center poll-text">{{ hasFeedback ?
                                    'Gracias por
                                    tu opinión.' : '¿Respondimos tus dudas?' }}</span>
                                <div class="thumbs">
                                    <button class="thumb-up"
                                        [ngClass]="{ 'selected': hasFeedback && ticketData.feedback }"
                                        [disabled]="hasFeedback" (click)="pullFeedback(true)">
                                        <span>👍</span>
                                    </button>
                                    <button class="thumb-down"
                                        [ngClass]="{ 'selected': hasFeedback && !ticketData.feedback }"
                                        [disabled]="hasFeedback" (click)="pullFeedback(false)">
                                        <span>👎</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="comment-box" *ngIf="commentAvailable" @fadeIn>
                            <div class="label" [ngClass]="{ 'collaborator': hasPermission, 'client': !hasPermission }">
                                {{ username }}
                                <span class="logo-agd" *ngIf="hasPermission">
                                    AGD
                                </span>
                            </div>
                            <form class="comment-box-container" [formGroup]="form">
                                <div class="comment-input">
                                    <button class="btn btn-outline-primary attachment-button" *ngIf="!hasPermission"
                                        (click)="inputAttachReference.click()" type="button"
                                        [disabled]="commentLoading">
                                        <mat-icon>attach_file</mat-icon>
                                        <input type="file" #inputAttachReference id="fileDropRef"
                                            (change)="handleFile($event)" />
                                    </button>
                                    <div class="input-area">
                                        <textarea rows="2" maxlength="250" formControlName="comentario"
                                            placeholder="Escibe una respuesta o consulta." required>
                                        </textarea>
                                        <div class="input-hint">
                                            <div class="d-flex flex-column overflow-hidden flex-grow-1">
                                                <small
                                                    *ngIf="form?.controls?.comentario?.touched && form?.controls?.comentario?.hasError('required')"
                                                    class="text-danger float-left">
                                                    Ingrese un comentario
                                                </small>
                                                <small *ngIf="invalidSizeError" class="text-danger float-left">
                                                    El archivo supera el tamaño máximo de 8 MB permitido
                                                </small>
                                                <small *ngIf="invalidFormatError" class="text-danger float-left">
                                                    El archivo no posee un formato válido
                                                </small>
                                                <small class="selected-filename" *ngIf="form.value.archivo">
                                                    <span class="material-icons-round">
                                                        description
                                                    </span>
                                                    <label>{{ form.value.archivo.name }}</label>
                                                    <button class="delete-button" mat-icon-button
                                                        (click)="clearSelection()" [disabled]="commentLoading"
                                                        title="Borrar">
                                                        <span class="material-icons-round">
                                                            clear
                                                        </span>
                                                    </button>
                                                </small>
                                            </div>
                                            <small class="char-count float-right">{{
                                                form?.value?.comentario?.length ||
                                                0 }}/250</small>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary btn-small" (click)="sendComment()"
                                        [disabled]="form.invalid || closeLoading">
                                        <div class="button-small-content" [ngClass]="{ invisible: commentLoading }"
                                            @fadeIn>
                                            <span class="material-icons-round">
                                                send
                                            </span>
                                            <div>{{ hasPermission ? 'Responder' : 'Enviar' }}</div>
                                        </div>
                                        <i class="spinner-icon" *ngIf="commentLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="19">
                                            </mat-progress-spinner>
                                        </i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="card-body p-0">
                            <div class="messages-timeline">
                                <div class="timeline" #timelineRef></div>
                                <div class="messages" #messagesContainerRef>
                                    <ng-container *ngFor="let messageGroup of messageGroups; let gNmbr = index">
                                        <div class="activity-marker" *ngIf="messageGroup.today && gNmbr !== 0">
                                            Hoy</div>
                                        <div class="message" [ngClass]="{ 'new-message': !message.visto }"
                                            *ngFor="let message of messageGroup.messages; let i = index" @fadeIn>
                                            <div class="label"
                                                [ngClass]="{ 'collaborator': message.respuesta, 'client': !message.respuesta }">
                                                <div>{{ message.nombre ? message.nombre + ' ' +
                                                    (message.apellido || '')
                                                    : message.usuario }}
                                                    <span class="logo-agd" *ngIf="message.respuesta">
                                                        AGD
                                                    </span>
                                                </div>
                                                <div class="message-date"
                                                    *ngIf="i === messageGroup.messages?.length - 1 && message.visto"
                                                    [ngClass]="{ 'current-date': messageGroup.today }">
                                                    {{ message.fecha | date: 'dd/MM/yyyy' }}
                                                </div>
                                                <div class="message-new" *ngIf="!message.visto">Nuevo</div>
                                            </div>
                                            <div class="message-text">
                                                <div class="message-description">
                                                    <div>
                                                        {{ message.mensaje }}
                                                    </div>
                                                    <div>
                                                        <agd-file-preview *ngFor="let attachment of message.adjuntos"
                                                            [thumbnail]="attachment.urlThumbnail"
                                                            (clicked)="downloadAttachmentFile(attachment.url)">
                                                        </agd-file-preview>
                                                    </div>
                                                </div>
                                                <div class="message-hour">
                                                    {{ message.fecha | date: 'HH:mm' }}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="d-flex align-items-center justify-content-center h-100" *ngIf="dataStatus === statusEnum.LOADING">
            <app-table-status [tableStatus]="dataStatus"></app-table-status>
        </div>
    </section>
</div>