<div class="content">
  <!-- Content -->
  <section class="content-page flex-grow-1">
    <div class="toolbar">
      <div [ngClass]="{'mobile-screen': mobileScreen}" class="d-flex align-items-center"
        *ngIf="dataSourceTotal !== null" @fadeIn>
        <h2 [ngClass]="{'mobile-screen': mobileScreen}" class="mr-5 reclamos-subtitle">{{ dataSourceTotal }} Consultas
        </h2>
        <agd-filters-toggle [class.d-none]="!(dataSourceTotal > 0 || !noFilters)" [filters]="filters"
          (filterValueChange)="applyFilters($event)">
        </agd-filters-toggle>
      </div>

      <button *ngIf="!hasHelpDeskRole" type="button" [ngClass]="{'mobile-screen': mobileScreen}"
        class="btn btn-secondary" (click)="openNewClaim()">
        <mat-icon class="material-symbols-outlined">add</mat-icon>
        Crear
      </button>
    </div>
    <agd-filters-toolbar-anchor></agd-filters-toolbar-anchor>
    <agd-cards-list [ngClass]="{'mobile-screen': mobileScreen}" @fadeIn
      *ngIf="dataSourceTotal > 0 || gridStatus === statusEnum.LOADING" [loading]="gridStatus === statusEnum.LOADING">
      <agd-cards-list-header>
        <agd-card-header-column>Consulta N°</agd-card-header-column>
        <agd-card-header-column>Estado</agd-card-header-column>
        <agd-card-header-column>Motivo/Submotivo</agd-card-header-column>
        <agd-card-header-column *ngIf="hasHelpDeskRole">Usuario</agd-card-header-column>
        <agd-card-header-column *ngIf="hasHelpDeskRole">Cuit/Razón social</agd-card-header-column>
        <agd-card-header-column class="centered">Fecha de cierre</agd-card-header-column>
        <agd-card-header-column class="centered">Información</agd-card-header-column>
      </agd-cards-list-header>
      <agd-card *ngFor="let ticket of dataSource" (click)="viewDetails(ticket)">
        <agd-card-column class="fit-content nro-reclamo-ticket">
          <b class="large">{{ ticket.nroTicket }}</b>
        </agd-card-column>
        <agd-card-column class="fit-content estado-reclamo">
          <agd-reclamo-estado-consulta [ticketStatus]="ticket.estado"></agd-reclamo-estado-consulta>
        </agd-card-column>
        <agd-card-column [ngClass]="{
          'open': !ticket.fechaFinalizacion,
          'help-desk-role': hasHelpDeskRole,}" class="motivo-reclamo">
          <div class="motivo-principal">{{ ticket.motivo }}</div>
          <div>{{ ticket.subMotivo }}</div>
        </agd-card-column>
        <agd-card-column *ngIf="hasHelpDeskRole" class="user-reclamo">
          <span class="material-icons">person</span><b>{{ ticket.usuario }}</b>
        </agd-card-column>
        <agd-card-column *ngIf="hasHelpDeskRole" class="cuit-reclamo">
          <div>
            {{ ticket.cuit | formatCUIT }}
          </div>
          <div>
            {{ ticket.razonSocial | lowercase }}
          </div>
        </agd-card-column>
        <agd-card-column class="centered fecha-finalizacion-reclamo">
          <ng-container *ngIf="ticket.fechaFinalizacion">
            {{ ticket.fechaFinalizacion | date: 'dd/MM/yyyy' }}
          </ng-container>
          <mat-icon class="small" *ngIf="!ticket.fechaFinalizacion">schedule</mat-icon>
        </agd-card-column>
        <agd-card-column class="fit-content iconos-reclamos">
          <div class="info-icons" *ngIf="!ticket.fechaFinalizacion">
            <mat-icon class="attachment-icon" [ngClass]="{ disabled: !ticket.adjunto }">
              attachment
            </mat-icon>
            <div>
              <mat-icon [ngClass]="{ dot: ticket.nroMensajes, disabled: !ticket.nroMensajes }">
                message
              </mat-icon>
              <span class="ml-2" *ngIf="ticket.nroMensajes">{{ ticket.nroMensajes }}</span>
            </div>
          </div>
          <div class="feedback-icons" *ngIf="ticket.fechaFinalizacion">
            <div class="thumb-up" [ngClass]="{
                'selected': ticket?.feedback !== null && ticket.feedback,
                'disabled': ticket?.feedback !== null && !ticket.feedback
              }">
              👍
            </div>
            <div class="thumb-down" [ngClass]="{
                'selected': ticket?.feedback !== null && !ticket.feedback,
                'disabled': ticket?.feedback !== null && ticket.feedback
              }">
              👎
            </div>
          </div>
        </agd-card-column>
      </agd-card>
    </agd-cards-list>

    <agd-card-empty-list @fadeIn *ngIf="gridStatus !== statusEnum.LOADING && dataSourceTotal === 0" class="flex-grow-1"
      [title]="noFilters ? emptyListTitle : 'Parece que no hay resultados'" [filtered]="!noFilters">
      <div class="empty-list-description">
        <!-- Description for filtered lists -->
        <ul class="text-left" *ngIf="!noFilters">
          <li>Utiliza <b>menos filtros.</b></li>
          <li><b>Revisa</b> los <b>parámetros</b> en las opciones de filtros.</li>
        </ul>
        <!-- Description for not filtered lists with 'MesaDeAyuda' role -->
        <div *ngIf="noFilters && hasHelpDeskRole">
          De momento no hay ninguna consulta que atender.
        </div>
        <!-- Description for not filtered lists for clients -->
        <div *ngIf="noFilters && !hasHelpDeskRole">
          Para cargar una consulta presiona el botón de nueva consulta.
        </div>
      </div>
    </agd-card-empty-list>
    <agd-paginator [hidden]="!(gridStatus === statusEnum.DONE)" [totalItems]="dataSourceTotal" [pageSize]="8"
      (pageChange)="handlePage($event)">
    </agd-paginator>
  </section>
</div>