import {
  Validators, FormBuilder, FormGroup, FormControl
} from '@angular/forms';
import { TableStatusEnum } from '../../../../../../_shared/components/table-status/table-status.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { ReclamosService } from '../../services/reclamos.service';
import { SubSink } from 'subsink';
import {
  TicketData, TicketStatus, TicketMessage
} from '../../interfaces/reclamos.interfaces';
import { USER_ROLES } from '../../../../../../_shared/helpers/applicationConstants';
import { UserDataService } from '../../../../../../_core/authentication/user-data.service';
import {
  Component, OnInit, OnDestroy, AfterViewChecked, Renderer2, ViewChild, ElementRef
} from '@angular/core';
import moment from 'moment';
import { AppAnimations } from 'src/app/_shared/animations/animations';
import { fileFormatValidator, fileSizeValidator } from 'src/app/_shared/helpers/formsCustomValidators';
import { CuitChangedService } from '../../../../../../_core/services/common/cuit-changed/cuit-changed.service';
import { completeSubscription } from 'src/app/_shared/helpers/listRole';
import { Subject } from 'rxjs';
import FileSaver from 'file-saver';

interface MessageGroup {
  today: boolean;
  messages: TicketMessage[];
}

@Component({
  selector: 'app-reclamo-detalle-page',
  templateUrl: './reclamo-detalle-page.component.html',
  styleUrls: ['./reclamo-detalle-page.component.scss'],
  animations: [AppAnimations.fadeIn, AppAnimations.fadeOut],
})
export class ReclamoDetallePageComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('fileDropRef') fileInput: ElementRef;

  @ViewChild('messagesContainerRef', { read: ElementRef }) messagesContainerRef: ElementRef;

  @ViewChild('timelineRef', { read: ElementRef }) timelineRef: ElementRef;

  /**
   * True if the current user has the "Mesa de ayuda" role
   */
  hasPermission = false;

  /**
   * True if the current ticket can be closed by the current user
   */
  canClose = false;

  /**
   * True if the ticket is closed
   */
  reOpen = false;

  /**
   * True if the comment box is available
   */
  commentAvailable = false;

  commentLoading = false;

  closeLoading = false;

  disabled = false;

  selected: File;

  invalidFormatError = false;

  invalidSizeError = false;

  username: string;

  ticketData: TicketData;

  dataStatus = TableStatusEnum.LOADING;

  statusEnum = TableStatusEnum;

  messageGroups: MessageGroup[];

  form: FormGroup;

  validFileFormats = `
    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
    application/pdf,
    image/jpeg,
    image/png,
    application/msword,
    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
    application/vnd.ms-powerpoint,
    application/vnd.openxmlformats-officedocument.presentationml.presentation,
    image/bmp,
    image/gif
  `;

  /**
   * Url to be used by the main header to navigate to the previous page
   */
  backTo: string;

  ngUnsubscribe = new Subject();

  private subs = new SubSink();

  private downloading = false;

  private sendingFeedback = false;

  constructor(
    private userDataService: UserDataService,
    private reclamosService: ReclamosService,
    private readonly cuitChangedService: CuitChangedService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private renderer: Renderer2,
  ) {
    /**
     * TO DO: implement a more robust navigation service after redesign
     */
    // We read 'useBackHistory' from router state to navigate with browser history
    this.backTo = this.router.getCurrentNavigation()?.extras.state?.useBackHistory ? '..' : '/mesa-de-ayuda/consultas';
  }

  get hasFeedback() {
    return this.ticketData?.feedback !== null;
  }

  ngOnInit(): void {
    this.initView(true);
  }

  ngAfterViewChecked(): void {
    if (!(this.messagesContainerRef || this.timelineRef)) return;
    // Magic ✨
    const messages = this.messagesContainerRef.nativeElement;
    const containerHeight = messages.offsetHeight;
    const lastMessageHeight = messages.children[0].offsetHeight;
    const bulletOffset = 28;
    const timelineHeight = `${containerHeight - lastMessageHeight}px`;

    // Set the timeline height size
    this.renderer.setStyle(this.timelineRef.nativeElement, 'height', timelineHeight);
    this.renderer.setStyle(this.timelineRef.nativeElement, 'margin-top', `${bulletOffset}px`);
  }

  ngAfterViewInit(): void {
    this.subs.sink = this.cuitChangedService.activeCuitChanged.subscribe((cuit) => this.cuitChanged(cuit));
    this.subs.sink = this.cuitChangedService.permissionsChanged.subscribe((permissions: any) => {
      // se verifica si trae permisos y en ese caso ejecuta el permission changed
      if (permissions) {
        this.permissionsChanged();
      }
    });
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async closeTicket() {
    if (this.closeLoading) return;

    const comment = this.form.get('comentario');

    comment.disable();

    this.closeLoading = true;

    const closed = await this.reclamosService.finalizeTicketConfirm(this.ticketData.nroTicket);

    if (closed) {
      this.subs.sink = this.reclamosService.getTicketDetailData(this.ticketData.nroTicket)
        .subscribe((data) => {
          this.ticketData = data;
          this.initView();
          comment.enable();
        }, () => {
          comment.enable();
          this.closeLoading = false;
        });
    } else {
      comment.enable();
      this.closeLoading = false;
    }
  }

  clearSelection() {
    const fileCtrl = this.form.get('archivo');
    fileCtrl.setValue(undefined);
    this.fileInput.nativeElement.value = null;
  }

  sendComment() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const comment = this.form.get('comentario');
    const attachment = this.form.get('archivo');

    this.commentLoading = true;

    comment.disable();

    this.subs.sink = this.reclamosService.sendTicketComment(this.ticketData.nroTicket, comment.value, attachment.value)
      .subscribe(() => {
        this.subs.sink = this.reclamosService.getTicketDetailData(this.ticketData.nroTicket)
          .subscribe((data) => {
            this.ticketData = data;
            comment.enable();
            this.initView();
            this.commentLoading = false;
          }, () => {
            comment.enable();
            this.commentLoading = false;
          });
      }, () => {
        comment.enable();
        this.commentLoading = false;
      });
  }

  handleFile(event) {
    if (this.disabled) return;

    const [file] = (event.target.files || []) as File[];

    this.selected = file;
    this.form.get('archivo').setValue(this.selected);
  }

  reOpenTicket() {
    this.commentAvailable = true;
    this.reOpen = false;
  }

  downloadAttachmentFile(url: string) {
    if (this.downloading) return;

    this.downloading = true;

    this.subs.sink = this.reclamosService.downloadAttachmentFileMessage(url)
      .subscribe((attachment: Blob) => {
        const blob = new Blob([attachment]);
        const fileToSave = window.URL.createObjectURL(blob);
        const filename = url.split('/').pop();
        const extensionFilename = filename.split('.').pop();
        FileSaver.saveAs(fileToSave, `adjunto_consulta_${this.ticketData.nroTicket}.${extensionFilename}`);
        this.downloading = false;
      }, () => {
        this.downloading = false;
      });
  }


  pullFeedback(feedback: boolean) {
    if (this.sendingFeedback || this.hasFeedback) return;

    this.sendingFeedback = true;

    this.subs.sink = this.reclamosService.sendFeedback(this.ticketData.nroTicket, feedback).subscribe((res: any) => {
      this.ticketData.feedback = feedback;
      this.sendingFeedback = false;
    }, () => {
      this.sendingFeedback = false;
    });
  }


  private cuitChanged(cuit: string) {
    this.completeSubscription();
    this.userDataService.setActiveCUIT(cuit);
    this.router.navigate(['mesa-de-ayuda/'], {
      state: { useBackHistory: true }
    });
  }

  /**
   * Unsubscribes all pending requests.
   */
  private completeSubscription() {
    completeSubscription(this.ngUnsubscribe);
  }

  /**
   * Sets permissions and refresh menu
   * @param permissions permissions
   */
  private permissionsChanged() {
    this.ngUnsubscribe = new Subject();
  }

  private initView(withSpinner = false) {
    this.dataStatus = withSpinner ? TableStatusEnum.LOADING : TableStatusEnum.DONE;

    this.downloading = false;

    this.hasPermission = this.userDataService.hasPermission(USER_ROLES.MESA_DE_AYUDA);

    this.username = this.userDataService.user.name;

    this.subs.sink = this.route.params.subscribe(params => {
      if (params.id) {
        this.reclamosService.getTicketDetailData(params.id).subscribe((ticketData) => {
          this.ticketData = ticketData;

          // If no ticket data was sent, redirect to ticket list
          if (this.ticketData.nroTicket == null) {
            this.router.navigateByUrl('/mesa-de-ayuda/consultas', { replaceUrl: true });
            return;
          }

          this.commentAvailable = (this.hasPermission && this.ticketData.estado !== TicketStatus.CERRADO)
            || this.ticketData.estado === TicketStatus.RESPONDIDO;

          this.reOpen = this.hasPermission && this.ticketData.estado === TicketStatus.CERRADO;

          this.canClose = this.ticketData?.estado !== TicketStatus.CERRADO;

          const hasUnseenMessages = this.ticketData.mensajes?.some(mensaje => (
            // if exists at least 1 unseen message from another user
            mensaje.usuario !== this.username && !mensaje.visto
          ));

          if (hasUnseenMessages) {
            this.reclamosService.markMessagesAsSeen(this.ticketData.nroTicket).toPromise();
          }

          this.form = this.fb.group({
            comentario: this.fb.control('', [Validators.required, Validators.maxLength(250)]),
            archivo: this.fb.control(null)
          });

          this.attachmentValidator();

          const messageRecords: Record<string, MessageGroup> = {};

          const currentDay = moment().format('DD-MM-YYYY');
          // Group messages by day
          this.ticketData.mensajes.forEach((message) => {
            const messageDay = moment(message.fecha).format('DD-MM-YYYY');

            if (messageRecords[messageDay]) {
              messageRecords[messageDay].messages.push(message);
            } else {
              messageRecords[messageDay] = {
                today: messageDay === currentDay,
                messages: [message]
              };
            }
          });

          this.messageGroups = Object.values(messageRecords);

          this.closeLoading = false;
          this.commentLoading = false;

          this.dataStatus = TableStatusEnum.DONE;
        }, () => {
          this.dataStatus = TableStatusEnum.ERROR;
        });
      }
    });
  }

  private attachmentValidator() {
    const fileCtrl = this.form.get('archivo');

    this.subs.sink = fileCtrl.valueChanges.subscribe(val => {
      if (!val) return;

      const fileFormatFn = fileFormatValidator(this.validFileFormats);
      const fileSizeFn = fileSizeValidator(8);

      this.invalidFormatError = false;
      this.invalidSizeError = false;

      if (fileFormatFn(fileCtrl as FormControl)) {
        this.invalidFormatError = true;
        fileCtrl.setValue(undefined);
        return;
      }

      if (fileSizeFn(fileCtrl as FormControl)) {
        this.invalidSizeError = true;
        fileCtrl.setValue(undefined);
      }
    });
  }
}
